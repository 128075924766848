// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { Form, Formik, FormikProps } from "formik"

import {
  Alert,
  Button,
  Heading,
  Loading,
  Select,
  Stack,
  Text,
  TextLink,
  Tooltip,
} from "@kiwicom/orbit-components/lib"

import { parseErrorMessage } from "api/errors"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import {
  EmberCurrencyInput,
  EmberRadioGroup,
  EmberTextInput,
} from "components/generic/formik-inputs"

import Yup from "logic/validation/yup-extended"

import { asyncFetchFromAPIBase, fetchWithAuth } from "utils/fetch-utils"
import {
  capitalizeFirstLetter,
  penniesInToPoundsStr,
  snakeCaseToTitleCase,
} from "utils/string-utils"
import { sortBy } from "utils/struct-utils"

const apiBaseUrl = process.env.GATSBY_API_BASE_URL

interface AlterStoreCreditModalProps {
  personUid: string
  currentBalance?: { cashable?: number; nonCashable?: number }
  handleClose: () => void
  handleSuccess: () => void
}

const AlterStoreCreditModal = ({
  personUid,
  currentBalance,
  handleClose,
  handleSuccess,
}: AlterStoreCreditModalProps) => {
  const [refunds, setRefunds] = useState([])

  let modalHeader = null
  let modalBody = null
  if (refunds.length > 0) {
    // If we've successfully completed a refund operation, replace the body
    // with just the receipt links. At this point also clicking the "X" to
    // close the modal is the same as clicking "close", it invokes
    // `handleSuccess`
    modalBody = (
      <RefundReceiptsBody refunds={refunds} handleSuccess={handleSuccess} />
    )
    handleClose = handleSuccess
  } else {
    if (currentBalance) {
      modalHeader = <ModalHeader currentBalance={currentBalance} />
    }
    modalBody = (
      <MainForm
        personUid={personUid}
        currentBalance={currentBalance}
        handleSuccess={handleSuccess}
        refunds={refunds}
        setRefunds={setRefunds}
      />
    )
  }

  return (
    <EmberModal size="small" onClose={handleClose}>
      <EmberModalHeader title="Change Credit" description={modalHeader} />
      <EmberModalSection>{modalBody}</EmberModalSection>
    </EmberModal>
  )
}

const MainForm = ({
  personUid,
  currentBalance,
  handleSuccess,
  refunds,
  setRefunds,
}) => {
  const [config, setConfig] = useState(null)
  const [configLoadingError, setConfigLoadingError] = useState(null)

  useEffect(() => {
    if (config === null) {
      const sub = fetchWithAuth(`${apiBaseUrl}/v1/credit/config/`).subscribe(
        (response) => {
          if (response.storeCredit) {
            preprocessConfig(response.storeCredit)
            setConfig(response.storeCredit)
          } else {
            setConfigLoadingError(parseErrorMessage(response))
          }
        }
      )
      return () => sub.unsubscribe()
    }
  }, [])

  if (configLoadingError) {
    return <Alert type="critical">{configLoadingError}</Alert>
  }
  if (!config) {
    return (
      <Stack align="center" justify="center">
        <Loading type="inlineLoader" />
      </Stack>
    )
  }

  return (
    <Stack spacing="large">
      <Formik
        initialValues={{
          operation: "add",
          amountInPounds: "",
          creditType:
            (config &&
              optionValueByName(config.creditType.enum, "NON_CASHABLE")) ||
            "",
          category:
            (config && optionValueByName(config.category.enum, "GOODWILL")) ||
            "",
          comment: "",
        }}
        enableReinitialize
        validationSchema={Yup.object({
          amountInPounds: Yup.number()
            .required("Please enter an amount, in pounds")
            .positive(),
          comment: Yup.string().strip(),
        })}
        onSubmit={(values, callbacks) => {
          submitForm(personUid, values, callbacks).then((response) => {
            if (response) {
              if (values.operation === "refund") {
                // If the operation is a refund, we don't close the modal right away,
                // we show the Stripe links instead.
                setRefunds(response)
              } else {
                handleSuccess()
              }
            }
          })
        }}
      >
        {(props) => formContents(currentBalance, config, refunds, props)}
      </Formik>
    </Stack>
  )
}

const formContents = (
  currentBalance,
  config,
  refunds,
  props: FormikProps<any>
) => {
  const {
    errors,
    isSubmitting,
    setFieldValue,
    status,
    values: { operation, amountInPounds, creditType, category },
  } = props
  return (
    <Form>
      <Stack spacing="large">
        <EmberRadioGroup
          name="operation"
          options={[
            { value: "add", label: "Add to this person's credit" },
            { value: "refund", label: "Refund credit to card" },
            {
              value: "remove",
              label: "Remove from this person's credit",
              selectedContent: (
                <Alert type="warning">
                  <Text>
                    This should generally only be done to correct mistakes or to
                    charge fees, not to refund cashable credit.
                  </Text>
                </Alert>
              ),
            },
          ]}
          onChange={(event) => {
            if (event.currentTarget.value === "refund") {
              setFieldValue(
                "creditType",
                optionValueByName(config.creditType.enum, "CASHABLE")
              )
              setFieldValue(
                "category",
                optionValueByName(config.category.enum, "REFUND")
              )
            } else if (event.currentTarget.value === "add") {
              setFieldValue(
                "creditType",
                optionValueByName(config.creditType.enum, "NON_CASHABLE")
              )
            }
          }}
        />
        <EmberCurrencyInput name="amountInPounds" label="Amount" />
        <Select
          name="creditType"
          label={
            <LabelWithInfoTooltip
              text="Credit Type"
              tooltip={`
                Cashable credit can be refunded to the user's payment card, non-cashable cannot.
                You can't add cashable credit to a user's account, it's only be created when
                we refund to Ember credit a ticket that had been paid by card.
              `}
            />
          }
          options={config.creditType.enum.map((option) => ({
            label: snakeCaseToTitleCase(option.name),
            value: option.value,
          }))}
          value={creditType}
          disabled={operation !== "remove"}
          onChange={(event) =>
            setFieldValue("creditType", event.currentTarget.value)
          }
        />
        <Select
          name="category"
          label="Category"
          options={config.category.enum.map((option) => ({
            label: snakeCaseToTitleCase(option.name),
            value: option.value,
          }))}
          value={category}
          disabled={operation === "refund"}
          onChange={(event) =>
            setFieldValue("category", event.currentTarget.value)
          }
        />
        <EmberTextInput
          name="comment"
          label={
            <LabelWithInfoTooltip
              text="Comment"
              tooltip={`
                Explain why we gave someone credit (e.g. because of a delay).
                Optional and private.
              `}
            />
          }
          disabled={operation === "refund"}
        />
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          fullWidth={true}
          submit={true}
          dataTest="submit"
        >
          {getLabelForSubmitButton({
            config,
            errors,
            operation,
            amountInPounds,
            creditType,
          })}
        </Button>
        {status?.error && <Alert type="critical">{status.error}</Alert>}
      </Stack>
    </Form>
  )
}

const RefundReceiptsBody = ({ refunds, handleSuccess }) => (
  // If we've just issued a refund and it has completed successfully,
  // replace the whole modal form with the receipt links and a button to
  // close the modal.
  <Stack>
    <Alert type="success">Successfully refunded</Alert>
    {refunds.map((refund, i) => (
      <Heading type="title3" key={i}>
        Refund: {penniesInToPoundsStr(refund.amount)},{" "}
        <TextLink href={refund.receiptUrl}>Receipt</TextLink>
      </Heading>
    ))}
    <Button onClick={() => handleSuccess()}>Close</Button>
  </Stack>
)

const ModalHeader = ({ currentBalance }) => (
  <Text>
    This person currently has{" "}
    <Text as="span" weight="bold">
      {penniesInToPoundsStr(currentBalance.cashable || 0)} cashable
    </Text>{" "}
    and{" "}
    <Text as="span" weight="bold">
      {penniesInToPoundsStr(currentBalance.nonCashable || 0)} non-cashable
    </Text>{" "}
    credit.
  </Text>
)

async function submitForm(personUid, values, { setSubmitting, setStatus }) {
  setSubmitting(true)
  setStatus({
    success: false,
    error: null,
  })
  const response = await submitApiRequest(personUid, values)
  setSubmitting(false)
  if (response.error) {
    setStatus({
      success: false,
      error: parseErrorMessage(response),
    })
    return null
  } else {
    setStatus({
      success: true,
      error: null,
    })
    return response
  }
}

async function submitApiRequest(personUid, values) {
  const { operation, amountInPounds, creditType, category, comment } = values
  const amountInPence = Math.round(100 * amountInPounds)
  let request
  if (operation === "refund") {
    request = {
      path: `/v1/people/${personUid}/`,
      method: "PUT",
      body: { cardRefund: amountInPence },
    }
  } else {
    const signedAmountInPence = amountInPence * (operation === "add" ? 1 : -1)
    request = {
      path: `/v1/people/${personUid}/credit/`,
      method: "POST",
      body: {
        creditType: `${creditType}`,
        amountInPence: signedAmountInPence,
        category: category,
        comment: comment,
      },
    }
  }
  return asyncFetchFromAPIBase(request)
}

function preprocessConfig(config) {
  // Make non-cashable the top option (and therefore the default) because it is by far the most
  // common operation.
  sortBy(config.creditType.enum, (creditType: { name: string }) =>
    creditType.name === "NON_CASHABLE"
      ? "$first"
      : snakeCaseToTitleCase(creditType.name)
  )

  // Make "reason not listed" the last option
  sortBy(config.category.enum, (category: { name: string }) =>
    category.name === "REASON_NOT_LISTED"
      ? "~last"
      : snakeCaseToTitleCase(category.name)
  )
}

function getLabelForSubmitButton({
  config,
  errors,
  operation,
  amountInPounds,
  creditType,
}): string {
  const amountText =
    amountInPounds && Object.keys(errors).length === 0
      ? `£${amountInPounds.toFixed(2)}`
      : ""
  let label = `${capitalizeFirstLetter(operation)} ${amountText}`

  // if the operation is "add" or "remove", add a suffix after the amount text
  // so that the button reads e.g. "Add £1.00 cashable credit".
  if (operation === "add" || operation === "remove") {
    const selectedTypeOption = config.creditType.enum.find(
      (opt) => opt.value == creditType
    )
    const typeText = selectedTypeOption
      ? snakeCaseToTitleCase(selectedTypeOption.name).toLowerCase() + " "
      : ""
    label += ` ${typeText}credit`
  }

  return label
}

function optionValueByName(
  options: Array<Record<string, string>>,
  optionName: string
) {
  const matchingOption = options.find((opt) => opt.name === optionName)
  return matchingOption?.value
}

const LabelWithInfoTooltip = ({ text, tooltip }) => (
  <>
    {`${text} `}
    <Tooltip content={tooltip}>ⓘ</Tooltip>
  </>
)

export default AlterStoreCreditModal

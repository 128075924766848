import { formatAsDateTime } from "utils/date-utils"
import { penniesInToPoundsStr } from "utils/string-utils"

const TypesToSummaryBuilders = {
  "emails/SEND_PASS": buildSendPassSummary,
  "emails/SEND_CANCEL_CONFIRMATION": buildCancellationConfirmationSummary,
  "emails/SEND_DELAY": buildSendDelaySummary,
  "emails/SEND_DELAY_COMPENSATION": buildDelayCompensationSummary,
  "emails/SEND_MAGIC_LINK": buildSendMagicLinkSummary,
  "emails/TIME_OFF_DECISION": buildTimeOffDecisionSummary,
  "emails/TIME_OFF_CANCELLED": buildTimeOffCancelledSummary,
  "emails/TIME_OFF_REQUEST_SNOOZED": buildTimeOffRequestSnoozedSummary,
  "emails/NO_SHOW_WARNING": buildNoShowWarningSummary,
  "emails/PARTIAL_NO_SHOW_WARNING": buildPartialNoShowWarningSummary,
  "emails/SEND_MULTIPLE_BOOKING_SAME_TRIP_WARNING":
    buildMultipleBookingSameTripWarningSummary,
  "emails/SEND_MULTIPLE_BOOKING_SAME_ROUTE_WARNING":
    buildMultipleBookingSameRouteWarningSummary,
  "emails/TEMPORARY_STOP": buildTemporaryStopSummary,
  "emails/TEMPORARY_STOP_MULTIPLE_ORDERS":
    buildTemporaryStopMultipleOrdersSummary,
  "emails/TEMPORARY_STOP_CANCELLATION": buildTemporaryStopCancellationSummary,
  "emails/TEMPORARY_STOP_CANCELLATION_MULTIPLE_ORDERS":
    buildTemporaryStopCancellationdMultipleOrdersSummary,
  "emails/STOP_CANCELLATION": buildStopCancellationSummary,
  "emails/SERVICE_CANCELLATION": buildServiceCancellationSummary,
}

// This takes the payload of a message and builds the subject line for the email. It should replicate exactly the same
// behavior as the emails-processor service.
export const buildEmailSummaryFromPayload = (payload: {
  [key: string]: any
}): string => {
  // NB "type" is the only field that is always set on payload. The rest depend on which type of message this is
  if (payload.message) {
    return payload.message
  }

  const buildSummary = TypesToSummaryBuilders[payload.type]

  if (!buildSummary) {
    // this would be a bug
    return payload.type
  }

  return buildSummary(payload)
}

function buildSendPassSummary({
  returnPass,
  outboundPass,
  isOrderModification,
}) {
  const passes = returnPass
    ? `(passes ${outboundPass.code} and ${returnPass.code})`
    : outboundPass
    ? `(pass ${outboundPass.code})`
    : ""
  if (isOrderModification) {
    return `Ticket changed: ${passes}`
  } else {
    const { legs } = outboundPass
    return (
      "Ticket sent:" +
      (returnPass ? " return ticket between " : " ticket from ") +
      outboundPass.legs[0].origin.regionName +
      (returnPass ? " and " : " to ") +
      legs[legs.length - 1].destination.regionName +
      ` ${passes}`
    )
  }
}

function buildCancellationConfirmationSummary({
  originRegionName,
  destinationRegionName,
}: {
  originRegionName: string
  destinationRegionName: string
}) {
  return `Ticket cancelled: from ${originRegionName} to ${destinationRegionName}`
}

function buildSendDelaySummary({
  originRegionName,
  destinationRegionName,
  expectedDelaySeconds,
}: {
  originRegionName: string
  destinationRegionName: string
  expectedDelaySeconds: number
}) {
  return (
    `Delay notification: bus from ${originRegionName} to ${destinationRegionName}` +
    ` is delayed by ${Math.round(expectedDelaySeconds / 60)} minutes`
  )
}

function buildDelayCompensationSummary({
  amount,
  tripOriginName,
  tripDestinationName,
}: {
  amount: number
  tripOriginName: string
  tripDestinationName: string
}) {
  return (
    `${penniesInToPoundsStr(amount)} compensation for delayed service` +
    ` from ${tripOriginName} to ${tripDestinationName}`
  )
}

function buildSendMagicLinkSummary() {
  return "Login to Ember"
}

function buildTimeOffDecisionSummary({
  decision,
  startDate,
  startTime,
  endDate,
  endTime,
}: {
  decision: string
  startDate: string
  startTime: string
  endDate: string
  endTime: string
}) {
  return `Time off ${decision}: ${startDate} ${startTime} to ${endDate} ${endTime}`
}

function buildTimeOffCancelledSummary({
  timeOffStart,
  timeOffEnd,
}: {
  timeOffStart: string
  timeOffEnd: string
}) {
  return `Time off cancelled: ${timeOffStart} to ${timeOffEnd}`
}

function buildTimeOffRequestSnoozedSummary({
  snoozeUntilDate,
}: {
  snoozeUntilDate: string
}) {
  return `Time off request snoozed until ${snoozeUntilDate}`
}

function buildMultipleBookingSameTripWarningSummary({
  bookings,
  timezone,
}: {
  bookings: Array<{
    originName: string
    destinationName: string
    departureDatetime: string
  }>
  timezone: string
}) {
  return `Multiple bookings on the same trip, starting at ${
    bookings[0].originName
  } at ${formatAsDateTime(bookings[0].departureDatetime, timezone)}`
}

function buildMultipleBookingSameRouteWarningSummary({
  bookings,
}: {
  bookings: Array<{ originName: string; destinationName: string }>
}) {
  return `Multiple bookings from ${bookings[0].originName} to ${bookings[0].destinationName}`
}

function buildNoShowWarningSummary({
  originName,
  destinationName,
  numberOfNoShows,
  departureDatetime,
  timezone,
}: {
  originName: string
  destinationName: string
  numberOfNoShows: number
  departureDatetime: string
  timezone: string
}) {
  const passengers = pluralise(numberOfNoShows, "passenger")
  const time = formatAsDateTime(departureDatetime, timezone)
  return `No show notification: ${passengers} from ${originName} to ${destinationName} at ${time}`
}

function buildPartialNoShowWarningSummary({
  originName,
  destinationName,
  numberOfNoShows,
  numberCheckedIn,
  departureDatetime,
  timezone,
}: {
  originName: string
  destinationName: string
  numberOfNoShows: number
  numberCheckedIn: number
  departureDatetime: string
  timezone: string
}) {
  const noShows = pluralise(numberOfNoShows, "no show")
  const checkIns = pluralise(numberCheckedIn, "passenger")
  const time = formatAsDateTime(departureDatetime, timezone)
  return `Partial no show notification: ${noShows} and ${checkIns} checked in from ${originName} to ${destinationName} at ${time}`
}

function pluralise(number: number, noun: string) {
  return `${number} ${noun}${number > 1 ? "s" : ""}`
}

function buildTemporaryStopSummary({
  originName,
  destinationName,
  departureDate,
  departureTime,
}: {
  originName: string
  destinationName: string
  departureDate: string
  departureTime: string
}) {
  return `Temporary stop: ${originName} changed to ${destinationName} for departure at ${departureTime} ${departureDate}`
}

function buildTemporaryStopMultipleOrdersSummary({
  originalStopName,
  replacementStopName,
  replacementStartTime,
  replacementStartDate,
  replacementEndTime,
  replacementEndDate,
}: {
  originalStopName: string
  replacementStopName: string
  replacementStartTime: string
  replacementStartDate: string
  replacementEndTime: string
  replacementEndDate: string
}) {
  return `Temporary stop for multiple orders: ${originalStopName} changed to ${replacementStopName} between ${replacementStartTime} ${replacementStartDate} and ${replacementEndTime} ${replacementEndDate}`
}

function buildTemporaryStopCancellationSummary({
  originalStopName,
  departureDate,
  departureTime,
}: {
  originalStopName: string
  departureTime: string
  departureDate: string
}) {
  return `Temporary stop cancelled: ${originalStopName} served again for departure at ${departureTime} ${departureDate}`
}

function buildTemporaryStopCancellationdMultipleOrdersSummary({
  originalStopName,
}: {
  originalStopName: string
}) {
  return `Temporary stop cancelled for multiple orders: ${originalStopName} served again`
}

function buildStopCancellationSummary({
  cancelledStopName,
  departureTime,
  departureDate,
  originName,
  destinationName,
}: {
  cancelledStopName: string
  departureTime: string
  departureDate: string
  originName: string
  destinationName: string
}) {
  return `${cancelledStopName} stop cancelled for departure at ${departureTime} ${departureDate} from ${originName} to ${destinationName}`
}

function buildServiceCancellationSummary({
  departureTime,
  departureDate,
  originName,
  destinationName,
}: {
  cancelledStopName: string
  departureTime: string
  departureDate: string
  originName: string
  destinationName: string
}) {
  return `Service cancelled for departure at ${departureTime} ${departureDate} from ${originName} to ${destinationName}`
}

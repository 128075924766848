import React, { useEffect, useState } from "react"

import { PageProps } from "gatsby"
import moment from "moment"
import styled, { css } from "styled-components"

import {
  Alert,
  Loading,
  Stack,
  Text,
  TextLink,
} from "@kiwicom/orbit-components"
import { Email, Sms } from "@kiwicom/orbit-components/icons"
import mq from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import { EmberApiError } from "api/errors"
import { fetchPersonDetails } from "api/person"

import { AccountPasses } from "components/account"
import Container from "components/container"
import {
  EmberCard,
  EmberCardGroup,
  EmberCardSection,
} from "components/generic/ember-card"
import { ScrollSection } from "components/generic/scroll-section"
import { AdminLayout, PageWrapper } from "components/layout-custom"
import AlterStoreCreditModal from "components/people/alter-store-credit-modal"
import EditProfileModal from "components/people/edit-profile-modal"
import ProfileCard from "components/people/profile-card"
import TransactionHistoryModal from "components/people/transaction-history-modal"

import { buildEmailSummaryFromPayload } from "logic/emails"

import { MessageLog } from "types/message-log"
import { PersonDetails } from "types/person"

import { getPersonName } from "utils/name-utils"
import { useGlobalDispatch, useGlobalState } from "utils/state-utils"
import { penniesInToPoundsStr } from "utils/string-utils"

function plural(count: number, word: string): string {
  // This is very crude and won't work with all English words obviously, but
  // it's good enough for the few uses of it in this file.
  if (count == null) {
    count = 0
  }
  const suffix = count == 1 ? "" : word.endsWith("s") ? "es" : "s"
  return `${count} ${word}${suffix}`
}

const MessageDatetime = styled.span`
  font-weight: medium;
  white-space: nowrap;
`

const MessageTile = ({ message }: { message: MessageLog }) => {
  const { medium, payload, sentAt } = message
  const icons = {
    sms: <Sms />,
    email: <Email />,
  }

  return (
    <EmberCard>
      <EmberCardSection>
        <MessageTileGrid>
          <Stack align="center">
            {icons[medium] ?? <Text>{medium}</Text>}
            <MessageDatetime>
              {moment(sentAt).format("HH:mm on DD MMM YYYY")}
            </MessageDatetime>
          </Stack>
          <Text>{buildEmailSummaryFromPayload(payload as any)}</Text>
        </MessageTileGrid>
      </EmberCardSection>
    </EmberCard>
  )
}

// Couldn't find a way of making this work without bringing in raw CSS, though it's probably feasible
const MessageTileGrid = styled.div`
  ${({ theme }) => css`
    display: grid;

    /* mobile screens: single column */
    grid-template-columns: auto;
    row-gap: ${theme.orbit.spaceMedium};

    /* desktop screens: two columns */
    ${mq.largeMobile(css`
      grid-template-columns: 15em auto;
      align-items: start;
    `)}
  `}
`

const Page = (props: PageProps) => {
  const { loggedInPersonUid } = useGlobalState()
  const customerUid = props.params.customerId

  const [customerDetails, setCustomerDetails] = useState<PersonDetails | null>(
    null
  )
  const [refreshCounter, setRefreshCounter] = useState<number>(0)
  const [showCreditModal, setShowCreditModal] = useState<boolean>(false)
  const [showTransactionHistoryModal, setShowTransactionHistoryModal] =
    useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<EmberApiError | null>(null)
  const [showAllOrders, setShowAllOrders] = useState<boolean>(false)
  const [showAllMessages, setShowAllMessages] = useState<boolean>(false)
  const [showEditProfileModal, setShowEditProfileModal] = useState(false)
  const dispatch = useGlobalDispatch()

  const passengerMessageLimit = 5

  useEffect(() => {
    setShowAllOrders(false)
    setShowAllMessages(false)
  }, [customerUid])

  useEffect(() => {
    if (customerUid != null) {
      setError(null)
      setLoading(true)
      setCustomerDetails(null)
      fetchPersonDetails({
        personUid: customerUid,
        passengerMessageLimit: showAllMessages ? 0 : passengerMessageLimit,
        onSuccess: setCustomerDetails,
        onError: setError,
        onCompletion: () => setLoading(false),
      })
    }
  }, [customerUid, refreshCounter, showAllOrders, showAllMessages])

  return (
    <AdminLayout title="Customer Details" padded={false}>
      <ScrollSection>
        <PageWrapper bottomPadding>
          <Container>
            {showCreditModal && (
              <AlterStoreCreditModal
                personUid={customerUid}
                currentBalance={customerDetails?.balance}
                handleClose={() => setShowCreditModal(false)}
                handleSuccess={() => {
                  setShowCreditModal(false)
                  setRefreshCounter(refreshCounter + 1)
                  if (customerUid == loggedInPersonUid) {
                    dispatch({ shouldRequestAccount: true })
                  }
                }}
              />
            )}
            {showTransactionHistoryModal && (
              <TransactionHistoryModal
                personUid={customerUid}
                handleClose={() => setShowTransactionHistoryModal(false)}
              />
            )}
            {showEditProfileModal && (
              <EditProfileModal
                personUid={customerUid}
                profile={customerDetails?.profile}
                handleClose={() => setShowEditProfileModal(false)}
                refreshOnEdit={() => setRefreshCounter(refreshCounter + 1)}
              />
            )}
            <Stack spacing="large" dataTest="admin-customer-details">
              {!customerUid ? (
                <Alert type="info">No customer selected</Alert>
              ) : loading ? (
                <Loading type="pageLoader" text="Loading customer details..." />
              ) : error != null ? (
                <Alert type="critical" title="Could not load customer details">
                  <Text>{error.message}</Text>
                </Alert>
              ) : (
                customerDetails != null && (
                  <>
                    <Text size="large" weight="bold">
                      {getPersonName(customerDetails?.profile) ??
                        customerDetails?.profile.email}
                    </Text>
                    <ProfileCard
                      personUid={customerUid}
                      profile={customerDetails.profile}
                      showEditModal={() => setShowEditProfileModal(true)}
                    />
                    <EmberCardGroup sectionTitle="Account Credit">
                      <EmberCard>
                        <EmberCardSection>
                          <Stack spacing="small">
                            <Text>
                              The customer has{" "}
                              {penniesInToPoundsStr(
                                customerDetails?.balance.total
                              )}{" "}
                              of credit, of which{" "}
                              {penniesInToPoundsStr(
                                customerDetails?.balance.cashable ?? 0
                              )}{" "}
                              is refundable to card.{" "}
                              <TextLink
                                type="secondary"
                                onClick={() =>
                                  setShowTransactionHistoryModal(true)
                                }
                              >
                                View History
                              </TextLink>
                            </Text>
                            <TextLink
                              type="secondary"
                              onClick={() => setShowCreditModal(true)}
                            >
                              Add, Refund or Remove Credit
                            </TextLink>
                          </Stack>
                        </EmberCardSection>
                      </EmberCard>
                    </EmberCardGroup>
                    {(customerDetails?.messages?.length ?? 0) > 0 && (
                      <EmberCardGroup
                        sectionTitle="Messages"
                        actions={[
                          <Text key="label">
                            {plural(customerDetails.messages.length, "message")}{" "}
                          </Text>,
                          customerDetails.messages.length ==
                            passengerMessageLimit &&
                            !showAllMessages && (
                              <TextLink
                                type="secondary"
                                key="show-all"
                                onClick={() => setShowAllMessages(true)}
                              >
                                Show all
                              </TextLink>
                            ),
                        ]}
                      >
                        {customerDetails.messages
                          .sort((a, b) => (a.sentAt > b.sentAt ? -1 : 1)) // most recent first
                          .map((message: MessageLog, index: number) => (
                            <MessageTile key={index} message={message} />
                          ))}
                      </EmberCardGroup>
                    )}
                    <AccountPasses audience="admin" personUid={customerUid} />
                  </>
                )
              )}
            </Stack>
          </Container>
        </PageWrapper>
      </ScrollSection>
    </AdminLayout>
  )
}

export default Page
